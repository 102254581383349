import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import SEO from "../components/seo"

import ButtonPrimary from "../components/button-primary"

import styles from "../components/book-archive.module.css"

const Books = ({ data }) => {
  return (
    <Layout colorScheme="red">
      <SEO title="Books" />

      <section
        className="container"
        style={{ paddingTop: "2rem", paddingBottom: "5rem", marginTop: "2rem" }}
      >
        <h1
          style={{
            fontSize: "3.875rem",
            color: "#fff",
            textAlign: "center",
            textShadow: "3px 3px 0 rgba(0,0,0,0.30)",
          }}
        >
          Books
        </h1>

        <div className={styles.archive}>
          {data.allBooksJson.edges.map(book => (
            <>
              <article className={styles.book}>
                {book.node.highlighted_award ? (
                  <span className={styles.award_banner}>
                    {book.node.highlighted_award}
                  </span>
                ) : null}
                <div className={styles.book_inner}>
                  <figure className={styles.cover}>
                    <a href={`/book/${book.node.slug}`}>
                      <img
                        src={book.node.image.publicURL}
                        alt={book.node.title}
                      />
                    </a>
                  </figure>
                  <h2 className={styles.title}>{book.node.title}</h2>
                  <p className={styles.subtitle}>{book.node.subtitle}</p>
                  <p className={styles.link}>
                    <ButtonPrimary
                      buttonText="More Info"
                      buttonType="primary"
                      buttonLink={`/book/${book.node.slug}`}
                    />
                  </p>
                </div>
              </article>
            </>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AllBooks {
    allBooksJson(sort: { fields: release_date, order: DESC }) {
      edges {
        node {
          title
          subtitle
          slug
          image {
            publicURL
          }
          highlighted_award
        }
      }
    }
  }
`

export default Books
